@import "design/variables";
@import "design/template/mixin_templates";

body > header {
    background-color: #8bc53f;
    border-bottom-color: #8bc53f;
}

.header_p {
    background-color: #8bc53f;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: #8bc53f;
    border-color: #8bc53f;
}

.pagination > li > a, .pagination > li > span {
    color: #8bc53f;
}

.pagination.pull-right li.active a {
    color: #fff !important;
}

.row.two-column-blog .apercu-article h2.titre-article, .single-article .titre-article h2,
.row.two-column-blog .apercu-article .h2.titre-article, .single-article .titre-article .h2 {
    color: #000 !important;
}

.content {
    border-left: #353535;
}

#navigation .triangle {
    border-bottom-color: transparent !important;
    border-bottom-width: 25px;
    border-bottom-style: solid;
    border-right: 10px solid #8bc53f;
    border-top-color: transparent !important;
    border-top-width: 25px;
    border-top-style: solid;
    content: "";
    display: block;
    left: -11px;
    position: absolute;
    z-index: 1;
}

.affichboutique ul li a {
    color: #fff;
}

.produit_etiquette:after {
    border-color: rgb(139, 197, 63) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);

}

.produit_etiquette {
    background: rgb(139, 197, 63);
}

.produit_etiquette2 {
    background: rgb(139, 197, 63);
}

.produit_etiquette2:after {
    border-color: rgba(0, 0, 0, 0) rgb(117, 168, 45) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);

}

.content #fiche-produit .remise-produit {
    background-color: #f5f5f5;
    color: white;
}

.content .addcommentaires {
    color: #000000;
}

.diaporama .triangle {
    border-left-color: #fff;
}

.diaporama:hover .triangle {
    border-left-color: #fff;
}

.diaporama {
    clear: both;
    margin-top: 20px;
    position: relative;
}

#nav-principal {
    border-color: #353535;
    .navigation_link_p{
        color: #fff;
    }
    .navigation_button_p{
        border-bottom-color: #353535;
        background-color: #8bc53f;
        &.actif, &:hover, &.sousmenu_ouvert{
            background: #353535;
            .navigation_link_p{
                color:#8bc53f;
            }
        }
        ul{
            border-color: #353535;
        }
    }
    .sous_navigation_link_p{
        color: #fff;
    }
    .sous_navigation_button_p{
        border-bottom-color: #353535;
        background: #8bc53f;
        &.actif, &:hover, &.sousmenu_ouvert{
            background: #353535;
            > .sous_navigation_link_p{
                color: #8bc53f;
            }
        }
    }
}

nav.arianne ul {
}

.galerie {
    border-top-color: #f5f5f5;
    box-shadow: 0 1px 2px #f5f5f5;
}

.mask > .commentaire {
    border-bottom-color: rgba(255, 255, 255, 0.3);
}

.produits {
    border-top-color: #f5f5f5;
}

.produits .desc {
    border-bottom-color: #f5f5f5;
    color: #000;
}

.produits .remise {
    background-color: #f5f5f5;
    color: #353535;
}

.message_lo {
    border-left-color: #f5f5f5;
    box-shadow: 0 1px 2px #f5f5f5;
    background: #8bc53f;
}

.content #fiche-produit .wrap-description .sociaux {
    border-color: #A37E4F;
}

body {
    background: none repeat scroll 0 0 #353535;
}

.links_p a {
    color: #fff;
}

.content {
    background: none repeat scroll 0 0 #353535;
}

.ie-inf-9 .message_lo {
    border-top-color: #bbb;
    border-bottom-color: #bbb;
    border-right-color: #bbb;
}

footer {
    background: none repeat scroll 0 0 #8bc53f;
    border-bottom: 1px solid #8bc53f;
    color: #fff;
    .links_p a {
        color: #fff;
    }
}

#iview-preloader div {
    background: #666;
}

.iview-caption {
    color: #f5f5f5;
}

.iview-caption.caption2 {
    background: #00b4ff;
}

.iview-caption.blackcaption {
    background: #f5f5f5;
    border-radius: 50%;
    padding: 5px;
    height: 80px;
    width: 80px;
    font-size: 9px;
}

.ie7 .adr {
    background: black;
}

.blackcaption .caption-contain {
    margin-top: 30px;
    padding: 0px;
}

#iview-preloader {
    border-color: #666;
}

.adresse {
    background: rgba(0, 0, 0, 0.8);
}

.links_p a{
    color:#8bc53f;
}

.links_p a.button, .links_p .button, .links_p button, .produits .addbasket, .links_p a.addmsglo, .addmsglo {
    background: none repeat scroll 0 0 #8bc53f;
    color: #fff;
    border-color: #8bc53f;
}

.links_p a.button:hover, .links_p .button:hover, .links_p button:hover, .produits .addbasket:hover, .links_p a.addmsglo:hover, .addmsglo:hover , .addbasketHover{
    background: none repeat scroll 0 0 #ddd;
    color: #8bc53f;
    border-color: #ddd;
}

.iview-caption.caption4 {
    background: #8bc53f;
    font-size: 16px;
    font-weight: bold;
}

.lien-mask {
    background: #000;
    background-color: #8bc53f;
}

.adr {
    color: #FFFFFF;
}

.url {
    color: #8bc53f;
}

.slogan, .mask > .commentaire a, .mask > h3, .mask > .h3, #footer a, .iview-caption a {
    color: #fff;
}

h2, .h2, .produits .nomprod {
    border-bottom-color: #f5f5f5;
    color: #f5f5f5;
    a, a:hover{
        color: inherit;
    }
}

.title_section a {
    color: #000;
}

nav.arianne ul li a, .fil-dariane a {
    color: #fff;
}

.title_section a:hover, .produits .oldprix {
    color: #f5f5f5;
}

.produits select, .form input[type=text], .form textarea {
    border-color: #f5f5f5;
}

.message_lo:before {
    color: #eee;
}

body {
    color: #fff;
}

.content .list-produits .mask .zoom, .content .button, .content bouton, .content .diaporama {
    color: #000000;
}

.content #fiche-produit .wrap-description .prix {
    border-color: #8bc53f;
}

.title_section a, title_section a:visited {
    color: #fff;
    text-decoration: none;
}

/* Template Panier */

$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

.template-panier{
    background-color:$panier-background-color;
    color:$panier-titre-color;
    .connexion p{
        border-color:$panier-titre-color;
        &, a{
            color:$panier-titre-color;
        }
    }
    #header-panier{
        color:$panier-titre-color;
    }
    #panier #paniercontent{
        background-color:$panier-content-background-color;
        &, #paniertable{
            color:$panier-texte-color;
        }
        border-color:$panier-background-color;
    }
    &.template-panier-1{
        #panier #paniercontent{
            &, #paniertable{
                color:$panier-titre-color;
            }
        }
    }
    .count-live{
        background:$panier-content-background-color;
        color:$panier-texte-color;
    }
}

.cmonsite-panier-2 .template-panier.template-panier-2 {
    #panier {
        background: #353535;
        #header-panier {
            color: #8BC53F;
        }
        #paniercontent {
            background: #8BC53F;
            &, #paniertable {
                color: #353535;
            }
        }
    }
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: #8bc53f;
    border-color: #8bc53f;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: #8bc53f;
    color: #8bc53f;

    a, span {
        color: #8bc53f;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}